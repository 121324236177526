import clsx from "clsx";
import Link from "next/link";

import { Avatar } from "@/features/common/components/Avatar";
import { JobStatusBadge } from "@/features/job/components/jobList/JobStatusBadge";
import { JobTypeBadge } from "@/features/job/components/jobList/JobTypeBadge";
import { JobFragment } from "@/generated/graphql-hooks";

export const JobInfoMainItem = ({
  job,
  timeDistanceToNow,
  tinyLayout = false,
}: {
  job: JobFragment;
  timeDistanceToNow: string;
  tinyLayout?: boolean;
}) => {
  return (
    <div className="flex flex-1 items-center space-x-2">
      <Link
        href={`/business/${job.creator.id}/${job.creator.username}`}
        passHref
        className="outline-none hover:opacity-90"
        onClick={(e) => e.stopPropagation()}
      >
        <Avatar
          className={
            tinyLayout ? "!h-11 !w-11" : "!h-11 !w-11 md:!h-14 md:!w-14"
          }
          publicId={job.creator.logoPublicId}
          name={job.creator.username}
        />
      </Link>
      <div className="flex-1 space-y-1 text-gray-700">
        <div className="flex items-center text-base font-normal sm:text-lg">
          <div
            className={clsx(
              "mr-1 inline-flex items-center",
              tinyLayout ? "" : "md:hidden",
            )}
          >
            <JobTypeBadge jobType={job.jobType} minimize />
          </div>
          <div className="flex flex-1 space-x-1">
            <JobStatusBadge
              className={clsx("hidden", tinyLayout ? "" : "md:flex")}
              job={job}
            />
            <div className="flex flex-1 items-center justify-between">
              <span>{job.title}</span>
              <JobTypeBadge
                className={clsx("hidden", tinyLayout ? "" : "md:inline-flex")}
                jobType={job.jobType}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className={clsx("text-sm", tinyLayout ? "" : "md:text-base")}>
            {job.creator.username}
          </span>
          <span className="text-sm">{timeDistanceToNow}前發佈</span>
        </div>
      </div>
    </div>
  );
};
