import { BadgeVariants } from "@/features/common/components/Badge";
import { JobApplicationStatusEnum } from "@/generated/graphql-hooks";

/**
 * @deprecated This mapping should not be used
 * use JobApplicationStatusBadgeForBusiness instead
 */
export const jobApplicationStatus = {
  [JobApplicationStatusEnum.Accepted]: "已接受",
  [JobApplicationStatusEnum.AcceptedByModel]: "已接受",
  [JobApplicationStatusEnum.Applied]: "已申請",
  [JobApplicationStatusEnum.Cancelled]: "取消",
  [JobApplicationStatusEnum.Invited]: "已邀請",
  [JobApplicationStatusEnum.Rejected]: "未接受",
  [JobApplicationStatusEnum.RejectedByModel]: "已婉拒",
};

/**
 * @deprecated This mapping should not be used
 * use JobApplicationStatusBadgeForBusiness instead
 */
export const jobApplicationStatusBadgeVariants: Record<
  JobApplicationStatusEnum,
  BadgeVariants
> = {
  [JobApplicationStatusEnum.Accepted]: "active",
  [JobApplicationStatusEnum.AcceptedByModel]: "active",
  [JobApplicationStatusEnum.Applied]: "warning",
  [JobApplicationStatusEnum.Cancelled]: "warning",
  [JobApplicationStatusEnum.Invited]: "warning",
  [JobApplicationStatusEnum.Rejected]: "danger",
  [JobApplicationStatusEnum.RejectedByModel]: "danger",
};

export const jobApplicationStatusResponse = {
  [JobApplicationStatusEnum.Accepted]: "成功申請",
  [JobApplicationStatusEnum.AcceptedByModel]: "成功配對",
  [JobApplicationStatusEnum.Applied]: "已申請",
  [JobApplicationStatusEnum.Rejected]: "未接受申請",
  [JobApplicationStatusEnum.RejectedByModel]: "已婉拒邀請",
  [JobApplicationStatusEnum.Invited]: "已邀請你",
};
