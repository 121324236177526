import clsx from "clsx";
import { useRouter } from "next/router";

import { Button } from "@/features/common/components/Button";
import { tailwindScreenSizes } from "@/features/common/consts/tailwindScreenSizes";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import { JobInfoDetailItem } from "@/features/job/components/jobList/JobInfoDetailItem";
import { JobInfoMainItem } from "@/features/job/components/jobList/JobInfoMainItem";
import { JobStatusBadge } from "@/features/job/components/jobList/JobStatusBadge";
import { jobScales } from "@/features/job/const/jobScales";
import { locations } from "@/features/job/const/locations";
import { useJobDateTime } from "@/features/job/hooks/useJobDateTime";
import { JobFragment } from "@/generated/graphql-hooks";
import CalendarIcon from "@/icons/calendar.svg";
import ClockIcon from "@/icons/clock.svg";
import HomeIcon from "@/icons/home.svg";
import LocationIcon from "@/icons/location.svg";
import MoneyIcon from "@/icons/money.svg";

export const JobCard = ({
  job,
  tinyLayout,
}: {
  job: JobFragment;
  tinyLayout?: boolean;
}) => {
  const { openDialog } = useDialogContext();
  const { date, timeDistanceToNow, timeInterval } = useJobDateTime(
    job.startAt,
    job.endAt,
    job.createdAt,
  );
  const { query, pathname, push } = useRouter();

  const onOpenJobDetails = () => {
    openDialog({
      job,
      view: DialogViewEnum.JobDetailsDialog,
      onClose: () => {
        const { jobId, ...rest } = query;
        push({ pathname, query: rest }, undefined, { scroll: false });
      },
    });
    push(
      {
        pathname,
        query: { ...query, jobId: job.id },
      },
      undefined,
      {
        scroll: false,
      },
    );
  };

  return (
    <div className="relative flex h-full overflow-hidden rounded-lg bg-white text-base font-light shadow-md md:text-sm">
      <div
        className="w-full"
        onClick={() => {
          if (window.innerWidth <= tailwindScreenSizes.sm) {
            onOpenJobDetails();
          }
        }}
      >
        <div className="space-y-4 py-5 px-4">
          <JobInfoMainItem
            job={job}
            timeDistanceToNow={timeDistanceToNow}
            tinyLayout={tinyLayout}
          />

          <div
            className={clsx(
              "text-gray-900",
              tinyLayout
                ? "grid grid-cols-2 gap-3"
                : "grid grid-cols-2 space-y-2.5 md:flex md:gap-8 md:items-baseline",
            )}
          >
            <JobInfoDetailItem icon={CalendarIcon} content={date} />
            <JobInfoDetailItem
              icon={ClockIcon}
              content={timeInterval ?? jobScales[job.scale]}
            />
            <JobInfoDetailItem
              icon={job.location === "workFromHome" ? HomeIcon : LocationIcon}
              content={locations[job.location] ?? "-"}
            />

            <div className={clsx(tinyLayout ? "" : "md:hidden")}>
              <JobInfoDetailItem
                icon={MoneyIcon}
                content={
                  <>
                    <span className="text-primary">${job.price}</span> /{" "}
                    {job.workingTime}
                    小時
                  </>
                }
              />
            </div>
          </div>
        </div>

        <div className={clsx(tinyLayout ? "" : "md:hidden")}>
          <JobStatusBadge
            className="w-full justify-center !rounded-t-none !rounded-b-lg"
            job={job}
          />
        </div>
      </div>

      <div
        className={clsx(
          "hidden w-[25%] flex-col items-center justify-center space-y-2 bg-gray-50",
          tinyLayout ? "" : "md:flex",
        )}
      >
        <div>
          <span className="text-primary text-xl font-normal">${job.price}</span>{" "}
          / {job.scale ? jobScales[job.scale] : `${job.workingTime}小時`}
        </div>
        <div>
          <Button variant="outline" size="sm" onClick={onOpenJobDetails}>
            查看更多
          </Button>
        </div>
      </div>
    </div>
  );
};
