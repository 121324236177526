import { twMerge } from "tailwind-merge";

import type { FCWithChildren } from "koljobs-types";

export type BadgeVariants =
  | "primary"
  | "secondary"
  | "active"
  | "inactive"
  | "warning"
  | "danger";

type BadgeProps = {
  className?: string;
  variant?: BadgeVariants;
};

const variantClasses = {
  primary: "bg-secondary text-primary",
  secondary: "bg-secondary text-white",
  active: "bg-green-100 text-green-800",
  inactive: "bg-gray-100 text-gray-700",
  warning: "bg-yellow-100 text-yellow-800",
  danger: "bg-red-100 text-red-800",
};

export const Badge: FCWithChildren<BadgeProps> = ({
  children,
  className,
  variant = "primary",
}) => {
  return (
    <span
      className={twMerge(
        "inline-flex items-center whitespace-nowrap rounded-full px-2.5 py-0.5 text-xs font-medium",
        variantClasses[variant],
        className,
      )}
    >
      {children}
    </span>
  );
};
