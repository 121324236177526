import { differenceInHours, format, formatDistanceToNow } from "date-fns";
import { zhHK } from "date-fns/locale";
import { useMemo } from "react";

export const useJobDateTime = (
  startDate: string | Date,
  endDate?: string | Date,
  createdAt?: string | Date,
) => {
  return useMemo(() => {
    const start =
      startDate instanceof Date ? startDate : new Date(startDate ?? 0);
    const date = format(start, "yyyy-MM-dd");
    const timeDistanceToNow =
      createdAt &&
      formatDistanceToNow(new Date(createdAt), {
        locale: zhHK,
      });

    if (!endDate) return { date, timeDistanceToNow };

    const end = endDate instanceof Date ? endDate : new Date(endDate);

    const startTime = format(start, "HH:mm");
    const endTime = format(end, "HH:mm");

    const timeDiff = differenceInHours(end, start);
    const timeInterval = `${startTime}~${endTime}`;

    return {
      date,
      timeDiff,
      timeInterval,
      timeDistanceToNow,
    };
  }, [startDate, endDate, createdAt]);
};
