import clsx from "clsx";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Badge } from "@/features/common/components/Badge";
import {
  jobApplicationStatusBadgeVariants,
  jobApplicationStatusResponse,
} from "@/features/job/const/jobApplicationStatus";
import {
  jobStatus,
  jobStatusBadgeVariants,
} from "@/features/job/const/jobStatus";
import {
  JobFragment,
  JobStatusEnum,
  UserRoleEnum,
} from "@/generated/graphql-hooks";

const jobStatusBadgeClassName = "!text-sm !rounded-[5px] !font-normal py-1";

export const JobStatusBadge = ({
  className,
  job,
}: {
  className?: string;
  job: JobFragment;
}) => {
  const { user } = useUserContext();

  if (
    user?.role === UserRoleEnum.Model &&
    !!jobApplicationStatusResponse[job.jobApplicationStatus] &&
    job.status !== JobStatusEnum.Closed
  ) {
    return (
      <Badge
        className={clsx(jobStatusBadgeClassName, className)}
        variant={jobApplicationStatusBadgeVariants[job.jobApplicationStatus]}
      >
        {jobApplicationStatusResponse[job.jobApplicationStatus]}
      </Badge>
    );
  }

  if (job.status === JobStatusEnum.Opened) {
    return null;
  }

  return (
    <Badge
      className={clsx(jobStatusBadgeClassName, className)}
      variant={jobStatusBadgeVariants[job.status]}
    >
      {jobStatus[job.status]}
    </Badge>
  );
};
