import { BadgeVariants } from "@/features/common/components/Badge";
import { JobStatusEnum } from "@/generated/graphql-hooks";

export const jobStatus = {
  [JobStatusEnum.Cancelled]: "已取消",
  [JobStatusEnum.Closed]: "已完結",
  [JobStatusEnum.Deleted]: "已刪除",
  [JobStatusEnum.Fulled]: "已滿額",
  [JobStatusEnum.Opened]: "可申請",
};

export const jobStatusBadgeVariants: Record<JobStatusEnum, BadgeVariants> = {
  [JobStatusEnum.Cancelled]: "inactive",
  [JobStatusEnum.Closed]: "inactive",
  [JobStatusEnum.Deleted]: "inactive",
  [JobStatusEnum.Fulled]: "inactive",
  [JobStatusEnum.Opened]: "active",
};
