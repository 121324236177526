import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import { JobFragment, useJobQuery } from "@/generated/graphql-hooks";

export const useJobDetailsDialog = ({
  jobs,
  isLoading,
}: {
  jobs: JobFragment[];
  isLoading: boolean;
}) => {
  const { state, openDialog } = useDialogContext();
  const { query } = useRouter();

  const job = useMemo(() => {
    if (isLoading || !query.jobId) return null;
    return jobs.find((job) => job.id === query.jobId);
  }, [isLoading, query, jobs]);

  const { data, isFetching } = useJobQuery(
    {
      where: {
        id: query.jobId as string,
      },
    },
    {
      enabled: !isLoading && !job && !!query.jobId,
    },
  );

  useEffect(() => {
    if (state.open || !query.jobId || isFetching || isLoading) return;
    openDialog({
      job: job ?? data?.job,
      view: DialogViewEnum.JobDetailsDialog,
    });
  }, [data, query, isFetching, isLoading]);
};
