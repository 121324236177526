export const locations = {
  central_and_western: "中西區",
  eastern: "東區",
  islands: "離島區",
  kowloon_city: "九龍城區",
  kwai_tsing: "葵青區",
  kwun_tong: "觀塘區",
  north: "北區",
  sai_kung: "西貢區",
  sha_tin: "沙田區",
  sham_shui_po: "深水埗區",
  southern: "南區",
  tai_po: "大埔區",
  tsuen_wan: "荃灣區",
  tuen_mun: "屯門區",
  wan_chai: "灣仔區",
  wong_tai_sin: "黃大仙區",
  yau_tsim_mong: "油尖旺區",
  yuen_long: "元朗區",
  workFromHome: "在家工作",
};
