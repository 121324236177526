import { twMerge } from "tailwind-merge";

type JobCardSkeletonProps = {
  containerClassName?: string;
};

export const JobCardSkeleton = ({
  containerClassName,
}: JobCardSkeletonProps) => {
  return (
    <div
      className={twMerge(
        "group relative flex overflow-hidden rounded-lg bg-white font-light shadow-md",
        containerClassName,
      )}
    >
      <div className="flex-1 space-y-4 py-5 px-4">
        <div className="flex justify-between">
          <div className="flex flex-1 flex-col space-y-5">
            <div className="flex space-x-4">
              <div className="h-11 w-11 rounded-full bg-slate-200 md:h-14 md:w-14"></div>
              <div className="flex flex-1 animate-pulse flex-col justify-center space-y-3">
                <div className="h-4 rounded bg-slate-200 md:w-1/2"></div>
                <div className="h-4 w-2/3 rounded bg-slate-200 md:w-1/3"></div>
              </div>
            </div>
            <div className="grid animate-pulse grid-cols-2 gap-3 text-gray-900 md:flex md:grid-cols-none md:gap-8">
              {Array.from(Array(3)).map((_, index) => (
                <div
                  className="h-4 w-full rounded bg-slate-200"
                  key={index}
                ></div>
              ))}
              <div className="h-4 w-full rounded bg-slate-200 md:hidden"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden  w-[25%] bg-gray-50 md:block"></div>
    </div>
  );
};
