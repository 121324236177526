import type { FunctionComponent, ReactNode, SVGProps } from "react";

export const JobInfoDetailItem = ({
  icon: Icon,
  content,
}: {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  content: string | ReactNode;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Icon width={20} height={20} />
      <span className="text-sm font-light text-gray-900 sm:text-base">
        {content}
      </span>
    </div>
  );
};
